var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-4",attrs:{"flat":""}},[_c('TitleBar',{scopedSlots:_vm._u([{key:"right-items",fn:function(){return [_c('PrimaryButton',{on:{"click":_vm.addForm}},[_vm._v("Add New")])]},proxy:true}])},[_vm._v(" Forms ")]),_c('SectionBar',{scopedSlots:_vm._u([{key:"left-items",fn:function(){return [_c('SearchTextField',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true}])}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.forms,"search":_vm.search},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.open",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.readonly",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value === 1 ? 'success' : 'warning'}},[_vm._v(_vm._s(value === 1 ? "Yes" : "No"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewForm(item.idform)}}},[_vm._v(" mdi-format-list-bulleted ")]),(
            item.readonly == false && item.is_workflow_scheduler_form == false
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editForm(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(
            item.readonly == false && item.is_workflow_scheduler_form == false
          )?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.confirmDelete(item.idform)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})],1),_c('v-dialog',{key:("new-" + _vm.showNewFormModal),attrs:{"max-width":"600px"},model:{value:(_vm.showNewFormModal),callback:function ($$v) {_vm.showNewFormModal=$$v},expression:"showNewFormModal"}},[_c('NewFormSheet',{on:{"done":_vm.formAdded,"cancel":function($event){_vm.showNewFormModal = false}}})],1),_c('v-dialog',{key:("edit-" + _vm.showEditFormModal),attrs:{"max-width":"600px"},model:{value:(_vm.showEditFormModal),callback:function ($$v) {_vm.showEditFormModal=$$v},expression:"showEditFormModal"}},[_c('EditFormSheet',{attrs:{"form":_vm.selectedForm},on:{"done":_vm.formAdded,"cancel":function($event){_vm.showEditFormModal = false}}})],1),_c('v-dialog',{attrs:{"max-width":"400px"},model:{value:(_vm.showHideModal),callback:function ($$v) {_vm.showHideModal=$$v},expression:"showHideModal"}},[_c('v-card',[_c('v-card-title',[_vm._v("Are you sure you want to delete?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.showHideModal = false}}},[_vm._v("Discard")]),_c('v-btn',{attrs:{"color":"warning"},on:{"click":_vm.deleteForm}},[_vm._v("OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }