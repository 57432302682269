<template>
  <v-card>
    <v-card-title>Edit Form</v-card-title>

    <v-card-text>
      <v-form>
        <InputTextField label="NAME" v-model="$v.current_form.name" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
      <PrimaryButton @click="ediForm">Save</PrimaryButton>
    </v-card-actions>
  </v-card>
</template>


<script>

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {

  props:{
    form:{required:true}
  },
  data() {
    return {
      current_form: {
        idform:null,
        name: null
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    current_form: {
      name: { required },
    },
  },

  methods: {
    async ediForm() {
      if (this.$v.current_form.$invalid) {
        this.$v.current_form.$touch()
        return
      }

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      const data = {
        name: this.current_form.name,
      };

      this.$emit('done');reuturn; // update form api not yet work
      try {
        await this.$http.patch("/api/form/"+this.current_form.idform, data, { headers })
        this.$emit('done');
      } catch (error) {
        console.log(error);
      }
    }
  },
  mounted(){
    this.current_form = this.$props.form
  },
  watch: {
    form: function (nVal, oVal) {
      this.current_form = nVal
    },
  }
}
</script>
