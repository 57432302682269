<template>
  <v-card>
    <v-card-title>Create Form</v-card-title>

    <v-card-text>
      <v-form>
        <InputTextField label="NAME" v-model="$v.form.name" />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <DismissButton @click="$emit('cancel')">Cancel</DismissButton>
      <PrimaryButton @click="createForm">Create</PrimaryButton>
    </v-card-actions>
  </v-card>
</template>


<script>

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {

  data() {
    return {
      form: {
        name: null
      }
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      name: { required },
    },
  },

  methods: {
    async createForm() {
      if (this.$v.form.$invalid) {
        this.$v.form.$touch()
        return
      }

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken")
      }
      const data = {
        name: this.form.name,
      };

      try {
        await this.$http.post("/api/form", data, { headers })
        this.$emit('done');
      } catch (error) {
        console.log(error);
      }
    }
  }

}
</script>
