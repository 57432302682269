<template>
  <div>
    <!-- Breadcrumbs -->

    <v-card class="ma-4" flat>
      <TitleBar>
        Forms
        <template #right-items>
          <PrimaryButton @click="addForm">Add New</PrimaryButton>
        </template>
      </TitleBar>
      <SectionBar>
        <template #left-items>
          <SearchTextField v-model="search" />
        </template>
      </SectionBar>
      <v-data-table :headers="headers" :items="forms" :search="search">
        <!-- eslint-disable-next-line -->
        <template #item.active="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.open="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.readonly="{ value }">
          <v-chip small :color="value === 1 ? 'success' : 'warning'">{{
            value === 1 ? "Yes" : "No"
          }}</v-chip>
        </template>
        <!-- eslint-disable-next-line -->
        <template #item.actions="{ item }">
          <v-icon small class="mr-2" @click="viewForm(item.idform)">
            mdi-format-list-bulleted
          </v-icon>
          <v-icon
            v-if="
              item.readonly == false && item.is_workflow_scheduler_form == false
            "
            small
            class="mr-2"
            @click="editForm(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="
              item.readonly == false && item.is_workflow_scheduler_form == false
            "
            small
            @click="confirmDelete(item.idform)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      max-width="600px"
      v-model="showNewFormModal"
      :key="`new-${showNewFormModal}`"
    >
      <NewFormSheet
        @done="formAdded"
        @cancel="showNewFormModal = false"
      />
    </v-dialog>
    <v-dialog
      max-width="600px"
      v-model="showEditFormModal"
      :key="`edit-${showEditFormModal}`"
    >
      <EditFormSheet
        :form="selectedForm"
        @done="formAdded"
        @cancel="showEditFormModal = false"
      />
    </v-dialog>

    <v-dialog max-width="400px" v-model="showHideModal">
      <v-card>
        <v-card-title>Are you sure you want to delete?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showHideModal = false">Discard</v-btn>
          <v-btn @click="deleteForm" color="warning">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import NewFormSheet from '../../components/Form/NewFormSheet.vue'
import EditFormSheet from '../../components/Form/EditFormSheet.vue'

export default {
  name: "Form",
  components: { NewFormSheet,EditFormSheet },
  data: function () {
    return {
      selectedForm:{},
      search: "",
      warningModal: false,
      showHideModal: false,
      getIdForm: "",
      forms: [],
      headers: [
        { value: "name", text: "Name" },
        { value: "readonly", text: "Readonly", width: "120px" },
        { value: "open", text: "Open", width: "120px" },
        { value: "active", text: "Active", width: "120px" },
        {
          value: "actions",
          text: "Action",
          width: "120px",
          sortable: false,
          filterable: false,
        },
      ],

      showNewFormModal: false,
      showEditFormModal: false
    };
  },
  mounted() {
    this.getForms();
  },
  methods: {
    async getForms() {
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };
      try {
        const response = await this.$http.get("/api/form", { headers });
        this.forms = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
    addForm() {
      this.editId = false
      this.showNewFormModal = true
    },
    formAdded() {
      this.showNewFormModal = false
      this.getForms()
    },
    viewForm: function (idform) {
      this.$router.push("/form/view/" + idform);
    },
    editForm(form) {
      this.selectedForm = form
      this.showEditFormModal = true
    },
    confirmDelete(idform) {
      this.showHideModal = true;
      this.getIdForm = idform;
    },
    async deleteForm() {
      this.showHideModal = false;
      this.$store.commit("set", ["modalSpinnerText", "Deleting..."]);
      this.$store.commit("toggle", "showHideModalSpinner");

      const headers = {
        Authorization: "Bearer " + this.$cookies.get("requestToken"),
      };

      try {
        await this.$http.delete("/api/form/" + this.getIdForm, { headers });
        this.getForms();
        this.$store.commit("toggle", "showHideModalSpinner");
      } catch (error) {
        console.log(error);
        this.$store.commit("toggle", "showHideModalSpinner");
      }
    },
  },
};
</script>
